import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";

export const Balance = () => {

    const dispatch = useDispatch<any>();
    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;

    return (<>
        <Box sx={{ height: 'calc(100vh - 80px)' }}>
            <Box sx={{ p: { xs: 1, sm: 1, md: 10 }, boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ p: 4, borderRadius: "32px", backgroundColor: "#070508db" }}>
                    <Typography variant="h6" component="h3" sx={{ flexGrow: 1, color: "#9a7fff", fontSize: { xs: "20px", sm: "20px", md: "40px" }, textAlign: "center" }}>
                        Balance
                    </Typography>
                    <hr />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "aquamarine", fontSize: { xs: "20px", sm: "20px", md: "40px" }, textAlign: "center" }}>
                        {parseFloat(loginInfo?.balance?.wax_balance)} WAX
                        {/* 9.00000000 WAX */}
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "aquamarine", fontSize: { xs: "20px", sm: "20px", md: "40px" }, textAlign: "center" }}>
                        {parseFloat(loginInfo?.balance?.grass_balance)} GRASS
                        {/* 23.5000 GRASS */}
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "aquamarine", fontSize: { xs: "20px", sm: "20px", md: "40px" }, textAlign: "center" }}>
                        {parseFloat(loginInfo?.balance?.gold_balance)} GOLD
                        {/* 9.5000 GOLD */}
                    </Typography>
                </Box>
            </Box>
        </Box>
    </>);
}



