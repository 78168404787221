import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { useDispatch, useSelector } from 'react-redux';

import { SessionKit } from "@wharfkit/session"
import { WebRenderer } from "@wharfkit/web-renderer"
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor"
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet'
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat'

import "./Header.css"

import { AppState } from "../store";
import { WalletLogin, WalletLogout } from '../store/user/actions';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const webRenderer = new WebRenderer()

const sessionKit = new SessionKit({
    appName: "wax deer exploration",
    chains: [
        {
            id: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
            url: "https://wax.greymass.com",
        },
    ],
    ui: webRenderer,
    walletPlugins: [new WalletPluginAnchor(), new WalletPluginCloudWallet(), new WalletPluginWombat()],
})

const drawerWidth = 240;

export const Header = () => {

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const location = useLocation();

    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;

    const [account, setAccount] = useState<string>("");

    async function login() {

        if (account != "") {

            if (window.confirm("Do you want to logout?")) {
                dispatch(WalletLogout());
                navigate("/");
            }
            return;
        }
        dispatch(WalletLogin());
    }

    async function logout() {

        if (account != "") {
            dispatch(WalletLogout());
            navigate("/");
        }
    }

    useEffect(() => {
        setAccount(loginInfo.account.toString());
        if (loginInfo.account.toString() == "") navigate("/");

    }, [loginInfo.isLogin]);

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const navItems = [
        "upgrade",
        "explore",
        "tools",
        "staking",
        "shop",
        "tournament",
        "leaderboard",
        "balance"
    ];

    const drawer = (
        <Box sx={{ textAlign: 'center', mt: 2, p: 3 }} onClick={handleDrawerToggle}>

            <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box sx={{ width: "20px", cursor: "pointer" }}>
                    <img src='/images/ic-close-red.png' />
                </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", mb: 4, mt: 2 }}>
                <Box sx={{ mb: 2, fontSize: "24px" }}>{account}</Box>
                <Button color={account == "" ? "primary" : "secondary"} variant="contained" onClick={login}>{account == "" ? "Login" : "Logout"}</Button>
            </Box>

            <Divider />
            {(account != "") && <List>
                {navItems.map((item) => (
                    <>
                        <ListItem key={item} disablePadding>
                            <Link to={"/" + item}>
                                <ListItemButton sx={{ textAlign: 'center' }}>
                                    <ListItemText sx={{ color: location.pathname.indexOf(item) == 1 ? '#ffa700' : '#fff' }} primary={item.toUpperCase()} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <Divider />
                    </>
                ))}
            </List>}
        </Box>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box sx={{ flexGrow: 1, maxWidth: "1400px", position: "relative", margin: "auto", zIndex: 100 }}>
            <img className="header-bg header-bg-pc" src={"/images/header_bg.png"} />
            <img className="header-bg header-bg-mobile" src={"/images/header_mobile_bg.png"} />
            <AppBar position="static" className='navigation-bar' sx={{ boxShadow: "none" }}>
                <Toolbar sx={{ paddingTop: { xs: "10px", sm: "10px", md: "16px" } }}>
                    <Link to={"/"} style={{ textDecoration: "none", color: "white", width: "14%", paddingLeft: "24px", boxSizing: "border-box" }}>
                        <Typography
                            variant="h6"
                            component="div"
                        >
                            WDJC
                        </Typography>
                    </Link>
                    {(account != "" || true) && <Box sx={{ display: { xs: 'none', sm: 'none', md: "block" }, ml: 6 }}>
                        {navItems.map((item, index) => (
                            <Link to={"/" + item}>
                                <Button key={item} sx={{
                                    color: location.pathname.indexOf(item) == 1 ? '#ffa700' : '#fff', fontWeight: "bold", '&:hover': {
                                        color: '#ffa700'
                                    },
                                    borderRight: (index + 1 != navItems.length) ? "1px solid grey" : "",
                                    borderRadius: 0,
                                    pl: 2,
                                    pr: 2
                                }}>
                                    {item}
                                </Button>
                            </Link>
                        ))}
                    </Box>}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "aquamarine", fontSize: "40px", textAlign: "left" }}>
                        {/* <p style={{ margin: 0 }}>WAX DEER</p> */}
                    </Typography>
                    <Box sx={{ width: "12%", textAlign: "-webkit-center" }}>
                        <Button sx={{ display: { xs: "none", sm: 'none', md: 'block' } }} color={account == "" ? "primary" : "secondary"} variant="contained" onClick={login}>{account == "" ? "Login" : account}</Button>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'block', md: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>

                </Toolbar>
            </AppBar>

            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    anchor="right"
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: "none" },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: "100%", sm: drawerWidth }, bgcolor: "#272d4d", color: "white" },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
};
