import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme, TextField, FormControl, InputLabel, Input, InputAdornment } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from '../store';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: "#e1ff49",
}));



const inputStyle: any = {

    color: "rgb(255, 255, 255)",
    width: "100%",
    position: "relative",
    fontWeight: "500",
    outline: "none",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderImage: "initial",
    flex: "1 1 auto",
    fontSize: "40px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0px",
    appearance: "textfield",
    height: "70px",
    background: "none",
    borderBottom: "0.0625rem solid rgb(92, 103, 125)",
    marginRight: "10px",
}

export const TournamentResultView = ({ id }: any) => {

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust 'sm' as 
    const [filteredRows, setFilteredRows] = useState<Array<any>>([]);

    const [rows, setRows] = useState<Array<any>>([]);

    useEffect(() => {

        let _rows = gameStatus.tournaments.filter((item: any) => item.id == -id);

        if (_rows && _rows.length > 0) {
            console.log(_rows[0]?.hist);
            setRows(_rows[0]?.hist);
            setFilteredRows(_rows[0]?.hist);
        }

    }, [JSON.stringify(gameStatus.tournaments.filter((item: any) => item.id == id))]);

    // const rows = [
    //     { round: 1, player1: "123123", player2: "2", winner: "123123123" },
    //     { round: 1, player1: "123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 1, player1: "123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 1, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 2, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 2, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 3, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 1, player1: "123123", player2: "2", winner: "123123123" },
    //     { round: 1, player1: "123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 1, player1: "123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 1, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 2, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 2, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 3, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 1, player1: "123123", player2: "2", winner: "123123123" },
    //     { round: 1, player1: "123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 1, player1: "123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 1, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 2, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 2, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    //     { round: 3, player1: "123123123", player2: "asdfasfd", winner: "123123123" },
    // ];

    const onChangeSearch = (e: any) => {
        let _value = e.target.value;
        setValue(_value);

        let _filteredRows = rows.filter((item: any) => JSON.stringify(item.player1).includes(_value) || JSON.stringify(item.player2).includes(_value));
        setFilteredRows(_filteredRows);
    }

    const [value, setValue] = useState("");


    return (<>
        <Box sx={{ width: "100%", textAlign: { xs: "center", sm: "center", md: "right" } }}>
            <FormControl variant="standard" sx={{ backgroundColor: "#3f3f3f", padding: "4px", color: "white", borderRadius: "10px" }}>
                <Input
                    id="input-with-icon-adornment"
                    sx={{ color: "white" }}
                    value={value}
                    onChange={onChangeSearch}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: "white" }} />
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
        <TableContainer component={Paper} sx={{ backgroundColor: "#000000d4" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>Round</StyledTableCell>
                        <StyledTableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>Player 1</StyledTableCell>
                        <StyledTableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>Player 2</StyledTableCell>
                        <StyledTableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>Winner</StyledTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredRows?.map((row: any, index: any) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell component="th" scope="row" align="center">{row.round}</StyledTableCell>
                            <StyledTableCell align="center">{`${row.player1.user} (${row.player1.asset_id})`}</StyledTableCell>
                            <StyledTableCell align="center">{`${row.player2.user} (${row.player2.asset_id})`}</StyledTableCell>
                            <StyledTableCell align="center">{`${row.winner.user} (${row.winner.asset_id})`}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>)
}