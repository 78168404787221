export const GetTimeString = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Pad with leading zeros if needed
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    if (hours > 24) return Math.floor(hours / 24) + " Days";


    if (hours == 0) {
        return (minutes == 0) ? paddedSeconds : paddedMinutes + ":" + paddedSeconds;
    }
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export const GetPeriodTimeString = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let result = "";
    if (hours > 0) result += hours + "h";
    if (minutes > 0) result += minutes + "m";
    if (seconds > 0) result += seconds + "s";

    if (hours > 24) return Math.floor(hours / 24) + " Days";

    return result;
}

export const RemoveDecimalFromBalance = (balance: string | undefined) => {

    if (!balance) return "";
    const numberPart = balance.split(' ')[0];
    const symbolPart = balance.split(' ')[1];

    return parseInt(numberPart) + " " + symbolPart;
}