import { Box, Grid } from "@mui/material";
import { MODE } from "../../constant";
import { useEffect, useState } from "react";
import { StakingPoolCard } from "../../components/StakingPoolCard";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

let uri_prefix = "https://ipfs.neftyblocks.io/ipfs/";

export const Staking = () => {

    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;

    const [pools, setPools] = useState([]);

    useEffect(() => {
        setPools(gameStatus.pool_info);
    }, [JSON.stringify(gameStatus.pool_info)]);

    const filters = ["All", "Fawn", "Abyssal"];
    const [filter, setFilter] = useState("All");

    const onChangeFilter = (e: any) => {
        setFilter(e.target.value);
    }

    return (
        <Box sx={{ height: 'calc(100vh - 80px)' }}>
            <Box sx={{ p: { xs: 1, sm: 1, md: 10 }, boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                {/* <h1 style={{ color: "#2203c1", marginTop: 0 }}>STAKING</h1> */}

                <h1 style={{ color: "#2203c1", position: "relative" }}>
                    <span style={{ position: "relative", zIndex: "100", color: "white", fontSize: "24px" }}>STAKING</span>
                    <img src="/images/title_bg.png" style={{
                        position: "absolute",
                        top: 0,
                        left: "50%",
                        width: "200px",
                        transform: "translate(-50%, -4px)",
                    }} />
                </h1>


                <Box sx={{ width: "100%", height: "90%", maxWidth: "1400px", p: 2, boxSizing: "border-box" }}>

                    <Box sx={{ backgroundColor: "#72058130", width: "100%", height: "100%", margin: "auto", borderRadius: "8px" }}>
                        <Box sx={{ overflowY: "auto", mt: 2, height: "100%" }}>
                            <Grid container sx={{ p: 2, display: "flex", alignItems: "center", height: "100%" }}>
                                {pools?.map((item: any, index: number) => (
                                    <StakingPoolCard
                                        id={item.id}
                                        reward_percent={item.reward_percent}
                                        stake_delay={item.stake_delay}
                                        is_open={false}
                                        stake_amount={item.balance}
                                        is_staked={item.is_staked}
                                        cooldown={item.cooldown_until}
                                    />
                                ))}

                            </Grid>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    );
}