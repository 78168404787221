import { Box, Button, Grid } from "@mui/material";
import { GAME_CONTRACT, MODE } from "../../constant";
import { useEffect, useState } from "react";
import InventoryItemCard from "../../components/InventoryItemCard";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { useNavigate } from "react-router-dom";
import { getRewardTokenBalance } from "../../store/user/actions";

// let uri_prefix = "https://ipfs.neftyblocks.io/ipfs/";
let uri_prefix = "https://atomichub-ipfs.com/ipfs/";

export const Inventory = () => {

    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;
    const navigate = useNavigate();

    const [nfts, setNFTs] = useState([]);
    const [filteredNfts, SetFilteredNfts] = useState([]);

    // const nfts = [
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 1" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 2" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 3" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 4" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 5" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 6" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 7" }, account: "", assetID: "1265465463" },
    // ];

    const filters = ["fawn"];
    const [filter, setFilter] = useState("fawn");

    const onChangeFilter = (e: any) => {
        setFilter(e.target.value);

        let _filteredNfts = nfts.filter((item: any) => item.schema.schema_name == e.target.value);
        _filteredNfts?.sort((a: any, b: any) => a.exploration_time - b.exploration_time);

        SetFilteredNfts(_filteredNfts);
        if (e.target.value == "all") SetFilteredNfts(nfts);
    }

    useEffect(() => {

        setNFTs(loginInfo.assets?.filter((item: any) => filters.includes(item.schema.schema_name)));
        SetFilteredNfts(loginInfo.assets?.filter((item: any) => filters.includes(item.schema.schema_name)));
    }, [JSON.stringify(loginInfo.assets)]);

    return (
        <Box sx={{ p: 1, px: { xs: 1, sm: 1, md: 10 } }}>

            <h1 style={{ color: "#2203c1", position: "relative" }}>
                <span style={{ position: "relative", zIndex: "100", color: "white", fontSize: "24px" }}>UPGRADE</span>
                <img src="/images/title_bg.png" style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    width: "200px",
                    transform: "translate(-50%, -4px)",
                }} />
            </h1>

            <Box sx={{ maxWidth: "1400px", margin: "auto" }}>
                <Box sx={{ my: 1, textAlign: "right" }}>
                    {false && <select className="rpc-endpoints" onChange={onChangeFilter} style={{ width: "140px", height: "40px", padding: 2, paddingLeft: 10 }}>
                        {filters.map((item) => (
                            <option key={item} value={item}>{item.toUpperCase()}</option>
                        ))}

                    </select>}
                </Box>

                <Box sx={{ bgcolor: "#000000b8", width: { xs: "100%", md: "100%" }, margin: "auto", borderRadius: "8px" }}>
                    <Box sx={{ maxHeight: "900px", overflowY: "scroll", height: "70vh", mt: 2 }}>
                        <Grid container sx={{ p: 2 }}>
                            {filteredNfts?.map((item: any, index: number) => (
                                <InventoryItemCard asset_id={item.asset_id} level={item.level} name={item.data.name} img_url={uri_prefix + item.data.img} />
                            ))}
                        </Grid>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
}