import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode, Navigation } from 'swiper/modules';
import { TournamentCard } from './TournamentCard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from '../store';
import { useEffect, useState } from 'react';
import { GetTournamentList } from '../store/user/actions';

export const TournamentListView = ({ setId }: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;

    // const tournamentList = [
    //     { id: 1, is_entered: true, start_time: 1731402987, end_time: 1731402987, winner: "123123", second_winner: "123123" },
    //     { id: 2, is_entered: true, start_time: 1731402987, end_time: 1731402987, winner: "123123", second_winner: "123123" },
    //     { id: 3, is_entered: true, start_time: 1731402987, end_time: 1731402987, winner: "123123", second_winner: "123123" },
    //     { id: 4, is_entered: true, start_time: 1731402987, end_time: 1731402987, winner: "123123", second_winner: "123123" },
    //     { id: 5, is_entered: true, start_time: 1731402987, end_time: 1731402987, winner: "123123", second_winner: "123123" },
    //     { id: 6, is_entered: true, start_time: 1731402987, end_time: 1731402987, winner: "123123", second_winner: "123123" },
    //     { id: 7, is_entered: true, start_time: 1731402987, end_time: 1731402987, winner: "123123", second_winner: "123123" },
    //     { id: 8, is_entered: true, start_time: 1731402987, end_time: 1731402987, winner: "123123", second_winner: "123123" },
    //     { id: 9, is_entered: false, start_time: 1731402987, end_time: 1731403987, winner: "123123", second_winner: "123123" },
    //     { id: 10, is_entered: false, start_time: 1731402987, end_time: 1731404987, winner: "123123", second_winner: "123123" },
    //     { id: 10, is_entered: false, start_time: 1731407987, end_time: 1732434987, winner: "123123", second_winner: "123123" },
    //     { id: 10, is_entered: true, start_time: 1731407987, end_time: 1732406987, winner: "123123", second_winner: "123123" },
    // ];

    const [tournamentList, setTournamentList] = useState<Array<any>>([]);


    useEffect(() => {
        setTournamentList(gameStatus.tournaments);
    }, [JSON.stringify(gameStatus.tournaments)]);

    return (
        <>
            <Swiper
                // slidesPerView={6}
                spaceBetween={30}
                slidesPerView={'auto'}
                centeredSlides={true}
                initialSlide={Math.max(0, tournamentList?.length - 2)}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Navigation]}
                className="mySwiper"
                style={{ width: "100%" }}
                breakpoints={{
                    300: {
                        slidesPerView: 1.5, // 1 slide for small screens
                    },
                    640: {
                        slidesPerView: 2, // 1 slide for small screens
                    },
                    768: {
                        slidesPerView: 3, // 2 slides for medium screens
                    },
                    1024: {
                        slidesPerView: 4, // 3 slides for large screens
                    },
                    1440: {
                        slidesPerView: 5, // 3 slides for large screens
                    },
                }}
            >
                {tournamentList.map((tournament) => (<>
                    <SwiperSlide>
                        <TournamentCard
                            id={tournament.id}
                            is_entered={tournament.is_entered}
                            start_time={tournament.start_time}
                            end_time={tournament.end_time}
                            winner1={tournament.winner1}
                            winner2={tournament.winner2}
                            setId={setId}
                            is_open={tournament.is_open}
                        />
                    </SwiperSlide>
                </>))}
            </Swiper>
        </>
    )
}