import { createStore, combineReducers, applyMiddleware, compose, Middleware } from "redux";
import { thunk } from 'redux-thunk';

import { todoReducer } from "./todo/reducers";
import { userReducer } from "./user/reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  userModule: userReducer,
  todoModule: todoReducer
});

export type AppState = ReturnType<typeof rootReducer>;



export default function configureStore() {
  const middlewares: any = [thunk];
  const middleWareEnhancer = applyMiddleware(...middlewares);
  // return createStore(rootReducer, composeEnhancers(middleWareEnhancer));


  return createStore<any, any, {}, {}>(
    rootReducer,
    composeEnhancers(middleWareEnhancer)
  );
}
