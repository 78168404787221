import { SessionKit } from "@wharfkit/session"
import { WebRenderer } from "@wharfkit/web-renderer"
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor"
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet'
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat'
import { Header } from "../components/Header"
import NFTCard from "../components/NFTCard"
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { AppState } from "../store";
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from "react"
import Modal from '@mui/material/Modal';

import { useDispatch, useSelector } from 'react-redux';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import "./World.css"
import { BuyWorldAction, ChangeEndPoint, SetWorldAction } from "../store/user/actions"
import { useNavigate } from "react-router-dom"
import { GetPeriodTimeString } from "../utiles"

const webRenderer = new WebRenderer()


const sessionKit = new SessionKit({
    appName: "wax deer exploration",
    chains: [
        {
            id: "73e4385a2708e6d7048834fbc1079f2fabb17b3c125b146af438971e90716c4d",
            url: "https://jungle4.greymass.com",
        },
    ],
    ui: webRenderer,
    walletPlugins: [new WalletPluginAnchor(), new WalletPluginCloudWallet(), new WalletPluginWombat()],
})

let uri_prefix = "https://ipfs.neftyblocks.io/ipfs/";

export const WorldPage = () => {

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;



    const onSelectWorld = (index: number) => {

        if (loginInfo.open_worlds.includes(index)) {

            dispatch(SetWorldAction(index));

            navigate("/explore-nfts?worldtype=" + index);
        }

        else {

            let cost = gameStatus.world_cost.find((item: any) => item.id == index);
            console.log(cost);
            console.log(cost.open_cost);

            setWorldCost(cost.open_cost);

            setRewardInfo(gameStatus.reward_info.find((item: any) => item.id == index))
            setBuyWorldIndex(index);

            handleOpen();
        }
    }

    const buyWorld = (index: number) => {
        dispatch(BuyWorldAction(index, (result: any) => {
            if (result) {
                handleClose();
            }
        }));
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [worldCost, setWorldCost] = useState("");
    const [buyWorldIndex, setBuyWorldIndex] = useState(0);
    const [rewardInfo, setRewardInfo] = useState<any>({
        stake_delay: 0,
        reward_min_range: 0,
        reward_max_range: 0
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxWidth: "calc(90% - 68px)",
        bgcolor: '#000000b8',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        color: "white",
        borderRadius: "36px"
    };

    const endpoints = [
        { url: 'https://wax.greymass.com' },
        { url: 'https://wax.pink.gg' },
        { url: 'https://api.wax.alohaeos.com' },
        { url: 'https://api.waxsweden.org' },
    ];

    const [selectedEndpoint, setSelectedEndpoint] = useState('https://wax.greymass.com');

    const onChangeEndpoint = (e: any) => {
        console.log("change rpc endpoint to " + e.target.value);
        setSelectedEndpoint(e.target.value);
        dispatch(ChangeEndPoint(selectedEndpoint));
    }

    const [world, setWorld] = useState(1);

    const showWorld = (dir: number) => {
        let _world = world + dir;
        _world = _world < 1 ? 1 : (_world > 3 ? 3 : _world);
        setWorld(_world);
        console.log(_world);
    }

    return (
        <>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center", color: "aquamarine", fontSize: "36px" }}>
                        BUY WORLD
                    </Typography>
                    <Box>
                        <img src={`/images/w${buyWorldIndex}.png`} />
                    </Box>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <strong>COST: </strong> {worldCost}
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <strong>Reward Range: </strong> {(rewardInfo.reward_min_range / 10000).toFixed(4)} - {(rewardInfo.reward_max_range / 1000).toFixed(4)} ANTLERS
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <strong>Exploration Time: </strong> {GetPeriodTimeString(rewardInfo.stake_delay)}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }} >
                        <Button color="primary" variant="contained" onClick={() => { buyWorld(buyWorldIndex) }} sx={{ textAlign: "center" }}>BUY WORLD</Button>
                    </Typography>
                </Box>
            </Modal>

            {loginInfo.isLogin ? (<>
                <Box className="pc-view">
                    <Box className="world-background">
                        <img src="/images/worldsbg.png" />
                    </Box>

                    <Box className={"world-land world-1"} onClick={(e) => { onSelectWorld(1) }} >
                        <img src="/images/w1.png" />
                    </Box>

                    <Box className={`world-land world-2 ${loginInfo.open_worlds.includes(2) ? "" : "opacity-50"} `} onClick={(e) => { onSelectWorld(2) }}>
                        <img src="/images/w2.png" />
                    </Box>

                    <Box className={`world-land world-3 ${loginInfo.open_worlds.includes(3) ? "" : "opacity-50"} `} onClick={(e) => { onSelectWorld(3) }}>
                        <img src="/images/w3.png" />
                    </Box>
                </Box>
                <Box className="mobile-view">

                    <Box className={`world-land  ${loginInfo.open_worlds.includes(world) ? "" : "opacity-50"} `} onClick={(e) => { onSelectWorld(world) }}>


                        <Typography id="modal-modal-description" sx={{ mt: 2, color: "white", fontSize: "36px" }}>
                            WORLD {world}
                        </Typography>

                        <img src={`/images/w${world}.png`} />
                    </Box>

                    <Box className="world-select-button" sx={{ display: "flex" }}>
                        <Button sx={{ pointerEvents: world == 1 ? "none" : "", opacity: world == 1 ? 0.5 : 1 }} color="success" variant="contained" onClick={() => { showWorld(-1) }}>Prev</Button>
                        <Button sx={{ pointerEvents: world == 3 ? "none" : "", opacity: world == 3 ? 0.5 : 1 }} color="success" variant="contained" onClick={() => { showWorld(1) }}>Next</Button>
                    </Box>
                </Box>
            </>) : (<>

                {/* <Box className="logo-panel">
                    <Box className="world-background-logo">
                        <img src="/images/logo.png" />
                    </Box>

                    <Box sx={{ mt: 6 }}>
                        <select className="rpc-endpoints" onChange={onChangeEndpoint}>
                            <option className="disablecolor" disabled value="none" style={{ textAlign: "center" }}>Select RPC Endpoint</option>
                            {endpoints.map((item) => (
                                <option key={item.url} value={item.url}>{item.url}</option>
                            ))}

                        </select>
                    </Box>
                </Box> */}
            </>)}
        </>
    );
};
