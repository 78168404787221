import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Home } from './pages/Home';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material'

import { useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import { WorldPage } from './pages/World';
import { Header } from './components/Header';
import { NotFound } from './pages/NotFound';
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './store';
import { ShowAlert } from './store/user/actions';
import { Inventory } from './pages/Inventory';
import { Staking } from './pages/Staking';
import { Login } from './pages/Login';
import { Tools } from './pages/Tools';
import { Shop } from './pages/Shop';
import { LeaderBoard } from './pages/LeaderBoard';
import { NFTDetail } from './pages/NFTDetail';
import { Tournament } from './pages/Tournament';
import { Balance } from './pages/Balance';




function App() {
  /*
    const [init, setInit] = useState(false);
  
    // this should be run only once per application lifetime
    useEffect(() => {
      initParticlesEngine(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadAll(engine);
        //await loadFull(engine);
        await loadSlim(engine);
        //await loadBasic(engine);
      }).then(() => {
        setInit(true);
      });
    }, []);
  
    const particlesLoaded: any = (container: any) => {
      console.log(container);
    };
  
    const options: any = useMemo(
      () => ({
  
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 6,
            straight: false,
          },
          number: {
            density: {
              enable: true,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }),
      [],
    );
  */

  const dispatch = useDispatch<any>();
  const userModule = useSelector((state: AppState) => state.userModule);
  const gameStatus = userModule.gameStatus;

  const successToast = (msg: string) => {
    toast.success(msg, {
      autoClose: 3000,
    });
  }

  const warningToast = (msg: string) => {
    toast.warning(msg, {
      autoClose: 3000,
    });
  }

  const errorToast = (msg: string) => {
    toast.error(msg, {
      autoClose: 3000,
    });
  }

  const showToast = (type: string, msg: string) => {
    if (type == "success") successToast(msg);
    if (type == "warning") warningToast(msg);
    if (type == "error") errorToast(msg);
  }

  useEffect(() => {
    if (gameStatus.alert.show) {
      showToast(gameStatus.alert.type, gameStatus.alert.message);

      dispatch(ShowAlert("", "", false));
    }
  }, [gameStatus.alert.show])

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(gameStatus.loading);
  }, [gameStatus.loading]);

  const [bgName, setBgName] = useState("main");

  return (
    <div className="App">

      {/* <Home /> */}
      {/* <WorldPage /> */}

      <Router>
        <div className="main-bg" style={{
          backgroundImage: "url(/images/main_bg.png)"
        }}>
          <Header />

          <Routes>

            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Login />} />
            <Route path="/explore" element={<WorldPage />} />
            <Route path="/explore-nfts" element={<Home />} />
            <Route path="/upgrade" element={<Inventory />} />
            <Route path="/staking" element={<Staking />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/tournament" element={<Tournament />} />
            <Route path="/detail/:id" element={<NFTDetail />} />
            <Route path="/balance" element={<Balance />} />

            <Route element={<NotFound />} /> {/* Fallback for unmatched routes */}
          </Routes>
        </div>
      </Router>


      <ToastContainer />

      {showLoading && <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "#000000bf", zIndex: "99999" }}>
        <Box sx={{ width: "200px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <img src="/images/earth_rot.gif" />
        </Box>
      </Box>}

    </div>
  );
}

export default App;
