import { Box, Button, Grid, Slider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InventoryItemCard from "../components/InventoryItemCard";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { MODE } from "../constant";
import NFTCard from "../components/NFTCard";
import { StakeTools } from "../store/user/actions";

// let uri_prefix = "https://ipfs.neftyblocks.io/ipfs/";
let uri_prefix = "https://atomichub-ipfs.com/ipfs/";

export const Tools = () => {

    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;
    const dispatch = useDispatch<any>();

    const [toolNfts, setToolNfts] = useState([]);
    const [filteredNfts, SetFilteredNfts] = useState([]);

    const filters = ["All", "Shovel", "Pickaxe"];
    const [filter, setFilter] = useState("All");
    const [selectedIds, setSelectedIds] = useState([]);

    const onChangeFilter = (e: any) => {
        setFilter(e.target.value);

        let _filteredNfts = toolNfts?.filter((item: any) => item.data.name.includes(e.target.value));
        _filteredNfts?.sort((a: any, b: any) => a.exploration_time - b.exploration_time);

        if (e.target.value == "All") SetFilteredNfts(toolNfts);
        else SetFilteredNfts(_filteredNfts);

        setSelectedIds([]);
    }

    useEffect(() => {

        let _tools = loginInfo.assets?.filter((item: any) => item?.schema?.schema_name == "tools");
        _tools?.sort((a: any, b: any) => a.exploration_time - b.exploration_time);
        setToolNfts(_tools);
        SetFilteredNfts(_tools);
        if (_tools && _tools.length > 0) {
            setValue(0);
            setSelectedIds([]);
        }
        console.log(_tools);

    }, [JSON.stringify(loginInfo.assets)]);

    const [value, setValue] = useState(0);


    const DoStake = () => {

        let temp_ids = ["855129", "855133", "855136", "855138", "855143", "855144", "855146", "855147"];
        let values = [1, 2, 5, 10];

        let _selected = toolNfts.filter((item: any) => selectedIds.find((id) => id == item.asset_id));

        let reward_amount = 0;

        console.log(_selected);

        _selected.map((item: any) => {
            let idx = temp_ids.indexOf(item.template.template_id);
            console.log(item.template.template_id, idx, values[Math.floor(idx / 2)]);
            if (idx != -1)
                reward_amount += values[Math.floor(idx / 2)];
        })

        dispatch(StakeTools(selectedIds, reward_amount, filter));
    }

    const onChangeSlide = (value: number) => {
        setValue(value);

        let _selectedIds: any = [];

        filteredNfts?.map((item: any, index: number) => {
            if (index < value)
                _selectedIds.push(item.asset_id);
        });

        setSelectedIds(_selectedIds);
    }

    const onClickNFT = (asset_id: any) => {
        console.log(asset_id);
        if (filter == "All") return;
        let _selectedIds: any = [...selectedIds];
        if (_selectedIds.find((id: any) => id == asset_id)) {
            _selectedIds = _selectedIds.filter((id: any) => id != asset_id);
            setValue(value - 1);
        } else {
            _selectedIds.push(asset_id);
            setValue(value + 1);
        }

        setSelectedIds(_selectedIds);
    }


    return (
        <Box sx={{ height: 'calc(100vh - 80px)' }}>
            <Box sx={{ p: { xs: 1, sm: 1, md: 10 }, boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                {/* <h1 style={{ color: "#2203c1" }}>TOOLS</h1> */}

                <h1 style={{ color: "#2203c1", position: "relative" }}>
                    <span style={{ position: "relative", zIndex: "100", color: "white", fontSize: "24px" }}>TOOLS</span>
                    <img src="/images/title_bg.png" style={{
                        position: "absolute",
                        top: 0,
                        left: "50%",
                        width: "200px",
                        transform: "translate(-50%, -4px)",
                    }} />
                </h1>

                {(toolNfts && toolNfts.length > 0) ? <>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ my: 1, textAlign: "right" }}>
                            <select className="rpc-endpoints" onChange={onChangeFilter} style={{ width: "140px", height: "40px", padding: 2, paddingLeft: 10 }}>
                                {filters.map((item) => (
                                    <option key={item} value={item}>{item.toUpperCase()}</option>
                                ))}

                            </select>
                        </Box>

                        <Box sx={{ bgcolor: "#41308530", width: { xs: "100%", md: "100%" }, margin: "auto", borderRadius: "8px" }}>
                            <Box sx={{ maxHeight: "600px", overflowY: "scroll", height: "50vh", mt: 2 }}>
                                <Grid container sx={{ p: 2 }}>
                                    {filteredNfts?.map((item: any, index: number) => (
                                        // <InventoryItemCard level={0} name={item.data.name} img_url={uri_prefix + item.data.img} />
                                        <>
                                            {
                                                MODE == "dev"
                                                    ? (<NFTCard
                                                        uri={uri_prefix + item.data.img}
                                                        name={item.data.name}
                                                        assetID={item.asset_id}
                                                        stakedTime={item.exploration_time}
                                                        isSelected={selectedIds.find((id) => id == item.asset_id)}
                                                        onClick={onClickNFT}
                                                    />)
                                                    : (<NFTCard
                                                        uri={uri_prefix + item.data.img}
                                                        name={item.data.name}
                                                        assetID={item.asset_id}
                                                        stakedTime={item.exploration_time}
                                                        isSelected={selectedIds.find((id) => id == item.asset_id)}
                                                        onClick={onClickNFT}
                                                    />)
                                            }
                                        </>
                                    ))}
                                </Grid>
                            </Box>

                        </Box>
                    </Box>
                    {filter != "All" && <>
                        <Box sx={{ width: "100%" }}>
                            <Slider
                                aria-label="Temperature"
                                defaultValue={30}
                                min={filteredNfts?.length > 0 ? 0 : 0}
                                max={filteredNfts?.length > 0 ? filteredNfts?.length : 0}
                                onChange={(e, value: any) => { onChangeSlide(value) }}
                                color="secondary"
                                value={value}

                                sx={{ width: "100%" }}
                            />

                            <Typography className="name" variant="h6" component="p" sx={{ flexGrow: 1, fontSize: { sm: "16px", md: "30px" }, color: "white", padding: "8px" }}>
                                {value} {filter.toUpperCase()} {value == 1 ? " Tool is" : " Tools are"} Selected
                            </Typography>
                        </Box>

                        <Box >
                            <Button color="primary" variant="contained" onClick={() => { DoStake() }}>Stake</Button>
                        </Box>
                    </>}
                </>
                    : <Box sx={{ backgroundColor: "#000000c2", borderRadius: "30px", color: "white", padding: "30px", boxSizing: "border-box" }}>
                        <h2>There is no tool NFT.</h2>
                        <h2>Buy tool NFT <a target="blank" href="https://wax.atomichub.io/market?blockchain=wax-mainnet&collection_name=waxdeerjc&order=asc&primary_chain=wax-mainnet&schema_name=tools&sort=price&symbol=WAX#sales">Here</a>.</h2>
                    </Box>}
            </Box>
        </Box>
    );
}