import { Box, Button } from "@mui/material"
import { useState } from "react";
import TokenSwapInput from "../components/TokenSwapInput";
import { useDispatch, useSelector } from "react-redux";
import { BuyGameTokenAction } from "../store/user/actions";
import { AppState } from "../store";


export const Shop = () => {

    const dispatch = useDispatch<any>();
    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;

    const [sell, setSell] = useState(0);
    const [life, setLife] = useState("0");
    const [wax, setWax] = useState("0");

    const clickBuyGold = () => {
        dispatch(BuyGameTokenAction(sell, sell * 10))
    }

    const onChangeBalance = (e: any) => {
        let _value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (!regex.test(_value)) {
            // If invalid, remove the last character or set to empty
            _value = _value.slice(0, -1);
        }

        setSell(Number(_value));
    }

    return (
        <Box sx={{ height: 'calc(100vh - 80px)' }}>
            <Box sx={{ p: { xs: 1, sm: 1, md: 10 }, boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                {/* <Box sx={{
                    backgroundImage: "url(/images/shop_panel.png)",
                    backgroundSize: "100% auto",
                    backgroundRepeat: "no-repeat",
                }}> */}
                <img src="/images/shop_panel.png" style={{ position: "absolute", zIndex: 1, width: "100%", maxWidth: "650px", height: "650px" }} />
                <Box style={{ zIndex: 2 }}>
                    <h1 style={{ color: "white" }}>SHOP</h1>

                    <Box sx={{ width: "100%", maxWidth: "500px", padding: { xs: "40px", sm: "40px", md: "20px" }, boxSizing: "border-box", backgroundColor: "#025ab714", borderRadius: "24px" }}>
                        <Box style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <Box sx={{ backgroundColor: "#3f2312", borderRadius: "14px", padding: "20px" }}>
                                <TokenSwapInput
                                    value={sell}
                                    onChange={onChangeBalance}
                                    token={"WAX"}
                                    img={"/images/token_wax.png"}
                                    type="sell"
                                    balance={parseFloat(loginInfo?.balance?.wax_balance) + "WAX"}
                                />
                            </Box>
                            <hr style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }} />
                            <Box sx={{ backgroundColor: "#3f2312", borderRadius: "14px", padding: "20px" }}>
                                <TokenSwapInput
                                    value={sell * 10}
                                    token={"GOLD"}
                                    img={"/images/token_gold.png"}
                                    type="receive"
                                    balance={parseFloat(loginInfo?.balance?.gold_balance) + "GOLD"}
                                />
                            </Box>
                        </Box>


                        <Button color="success" variant="contained" onClick={clickBuyGold} sx={{ color: "white", width: "100%", mt: 4, p: "16px", fontSize: "16px" }}>
                            <p>BUY GAME TOKEN</p>
                        </Button>
                    </Box>
                </Box>
                {/* </Box> */}
            </Box>
        </Box>
    )
}