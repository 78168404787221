
import { Header } from "../components/Header"
import NFTCard from "../components/NFTCard"
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from "react"
import { BuyWorldAction, Exploration, getRewardTokenBalance } from "../store/user/actions";
import { useNavigate } from "react-router-dom";
import { AppState } from "../store";
import { NFT_TYPE, MODE, GAME_CONTRACT } from "../constant";

let uri_prefix = "https://ipfs.neftyblocks.io/ipfs/";

export const Home = () => {

    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;

    const dispatch = useDispatch<any>();
    const nagivate = useNavigate();

    const DoExploration = () => {

        let asset_ids = [];
        for (var i = 0; i < value; i++) {
            asset_ids.push(filteredNfts[i].asset_id);
        }

        dispatch(Exploration(gameStatus.world, asset_ids, alignment == "promo"));
    }

    const [nfts, setNfts] = useState<any>([]);
    const [filteredNfts, SetFilteredNfts] = useState<any>([]);

    // const nfts = [
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 1" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 2" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 3" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 4" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 5" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 6" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 7" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 8" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 9" }, account: "", assetID: "1265465463" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 10" }, account: "", assetID: "1654654629" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 11" }, account: "", assetID: "1654654629" },
    //     { schema: { schema_name: "fawn" }, immutable_data: { img: "Qma2bc55xoFgQMt6eDBnhmZuvHUyTP7qedYzoAX1UoJhgE", name: "My NFT 12" }, account: "", assetID: "1654654629" },

    // ];

    const [value, setValue] = useState(1);

    useEffect(() => {

        if (loginInfo.isLogin == false) {
            nagivate("/");
        }

        setNfts(loginInfo.assets);
        // let _filteredNfts = nfts?.filter((item: any) => item.schema.schema_name == NFT_TYPE);
        let _filteredNfts = loginInfo.assets?.filter((item: any) => item.schema.schema_name == NFT_TYPE);
        _filteredNfts?.sort((a: any, b: any) => a.exploration_time - b.exploration_time);
        SetFilteredNfts(_filteredNfts);

    }, [JSON.stringify(loginInfo.assets)]);

    const [alignment, setAlignment] = useState(NFT_TYPE);

    const handleChangeNFTType = (e: any, value: string) => {

        console.log(value);
        if (value == "" || value == null) return;
        setAlignment(value);
        let _filteredNfts = nfts.filter((item: any) => item.schema.schema_name == value);

        if (value == "promo") {
            _filteredNfts = _filteredNfts.filter((item: any) => item.template.template_id == "859701");
        }

        _filteredNfts?.sort((a: any, b: any) => a.exploration_time - b.exploration_time);

        SetFilteredNfts(_filteredNfts);

        setValue(_filteredNfts.length > 0 ? 1 : 0);
    };


    return (
        <>
            <Box sx={{
                display: {
                    xs: 'block',  // Hide on extra small screens
                    sm: 'block',   // Hide on small screens
                    md: 'block',  // Show on medium screens and up
                },
                textAlign: "left",
            }}>
                <Button color="primary" variant="contained" sx={{ height: { xs: "24px", sm: "24px", md: "36px" }, m: 2 }} onClick={() => { nagivate("/explore") }}>Back</Button>
            </Box>

            <Box sx={{ bgcolor: "#41308530", width: { xs: "90%", md: "50%" }, margin: "auto", mt: { sx: 0, sm: 0, md: 4 }, padding: { xs: "10px", sm: "10px", md: "20px" }, borderRadius: "8px" }}>

                <Box sx={{ fontSize: { sm: "16px", md: "30px" }, color: "LIGHTGREEN" }}>
                    Select the NFT you want to explore
                </Box>

                <Box sx={{ textAlign: "right", mt: 2 }}>
                    <Box sx={{ display: "inline-block", backgroundColor: "#66cdaa", borderRadius: "50px" }}>
                        {/* <span style={{ marginLeft: "10px" }}>SHOW: &nbsp; </span> */}
                        <ToggleButtonGroup
                            color="secondary"
                            value={alignment}
                            exclusive
                            onChange={handleChangeNFTType}
                            aria-label="Platform"
                            sx={{
                                textAlign: "right",
                                color: "white",
                            }}
                        >
                            <ToggleButton value={NFT_TYPE} color="primary" sx={{ height: "30px" }}>{NFT_TYPE}</ToggleButton>
                            <ToggleButton value="abyssal" color="primary" sx={{ height: "30px" }}>Abyssal</ToggleButton>
                            <ToggleButton value="promo" color="primary" sx={{ height: "30px" }}>Promo</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>

                <Box sx={{ maxHeight: "600px", overflowY: "scroll", height: "50vh", mt: 2 }}>
                    <Grid container sx={{ p: 2 }}>
                        {filteredNfts?.map((item: any, index: number) => (<>
                            {
                                MODE == "dev"
                                    ? (<NFTCard uri={uri_prefix + item.data.img} name={item.data.name} assetID={item.asset_id} stakedTime={item.exploration_time} isSelected={index < value} />)
                                    : (<NFTCard uri={uri_prefix + item.data.img} name={item.data.name} assetID={item.asset_id} stakedTime={item.exploration_time} isSelected={index < value} />)
                            }

                        </>))}
                    </Grid>
                </Box>

                <Box sx={{ width: "100%" }}>
                    <Slider
                        aria-label="Temperature"
                        defaultValue={30}
                        min={filteredNfts?.length > 0 ? 1 : 0}
                        max={filteredNfts?.length > 0 ? filteredNfts?.length : 0}
                        onChange={(e, value: any) => { setValue(value) }}
                        color="secondary"
                        value={value}

                        sx={{ width: "100%" }}
                    />

                    <Typography className="name" variant="h6" component="p" sx={{ flexGrow: 1, fontSize: { sm: "16px", md: "30px" }, color: "white", padding: "8px" }}>
                        {value} {alignment.toUpperCase()} {value == 1 ? " NFT is" : " NFTs are"} Selected
                    </Typography>
                </Box>

                <Box >
                    <Button color="primary" variant="contained" onClick={() => { DoExploration() }}>Exploration</Button>
                </Box>

            </Box >
        </>
    );
};
