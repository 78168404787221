import { Box, Button, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppState } from "../store";
import { GetTimeString } from "../utiles";
import { LevelupBoost, LevelUpgrade } from "../store/user/actions";

let uri_prefix = "https://atomichub-ipfs.com/ipfs/";

export const NFTDetail = () => {

    const { id } = useParams();
    const userModule = useSelector((state: AppState) => state.userModule);
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;

    const [currentNFT, setCurrentNFT] = useState<any>();
    // const status: string = "Active"; // "Active" "Upgrading"

    useEffect(() => {

        if (loginInfo.account.toString() == "") {
            navigate("/");
            return;
        }

        let _currentNFT = loginInfo.assets?.find((nft: any) => nft?.asset_id == id?.toString());
        console.log(_currentNFT, loginInfo.assets);
        if (_currentNFT) {
            setCurrentNFT(_currentNFT);
        }

        const currentUnixTime = Math.floor(new Date().getTime() / 1000);
        let delayTime = _currentNFT.upgrade_end_at - currentUnixTime;
        console.log("=>>>>>>>>>>>>>>>>>>", _currentNFT.upgrade_end_at, currentUnixTime);
        if (delayTime > 0) {

            if (timer) {
                clearInterval(timer);
                timer = null;
            }

            setUTime(delayTime);
            startTimer();
        }


        return () => {
            if (timer) {
                clearInterval(timer);
                timer = null;
            }
        };

    }, [JSON.stringify(loginInfo.assets)]);

    const [uTime, setUTime] = useState(0);
    let timer: any = null;
    const startTimer = () => {
        if (timer == null) {
            timer = setInterval(() => {
                setUTime((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(timer);
                        timer = null;
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
    }

    const levelup = () => {
        dispatch(LevelUpgrade(id, currentNFT.level));
    }

    const levelupboost = () => {

        let boost_coast = Math.ceil(uTime / 60);

        dispatch(LevelupBoost(id, boost_coast));
    }

    return (<>

        <Box sx={{ height: 'calc(100vh - 64px)' }}>



            <Box sx={{ p: { xs: 1, sm: 1, md: 10 }, boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>

                <Box sx={{
                    display: {
                        xs: 'block',  // Hide on extra small screens
                        sm: 'block',   // Hide on small screens
                        md: 'block',  // Show on medium screens and up
                    },
                    textAlign: "left",
                    position: "absolute",
                    left: 0,
                    top: 0
                }}>
                    <Button color="primary" variant="contained" sx={{ height: { xs: "24px", sm: "24px", md: "36px" }, m: 2 }} onClick={() => { navigate("/upgrade") }}>Back</Button>
                </Box>

                <h1 style={{ color: "#2203c1" }}>NFT DETAIL </h1>

                <Box sx={{ width: "100%", maxWidth: "1200px" }}>
                    <Grid container spacing={4} sx={{ width: "100%" }}>
                        <Grid size={{ xs: 12, sm: 4, md: 4 }} sx={{ padding: "20px", boxSizing: "border-box", backgroundColor: "#000000d9", borderRadius: "12px" }}>
                            <Box sx={{ width: "80%", margin: "auto" }}>
                                <img src={uri_prefix + currentNFT?.data?.img} />
                                {/* <img src="https://atomichub-ipfs.com/ipfs/QmejWkJejg4v3TbKMiFUHtwPnDtvmLFH9J8GXaXfN5VEtc" /> */}
                            </Box>
                        </Grid>

                        <Grid size={{ xs: 12, sm: 8, md: 8 }} sx={{ p: 6, py: 1, boxSizing: "border-box", backgroundColor: "#000000d9", borderRadius: "12px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box sx={{ textAlign: "left", color: "white" }}>
                                <Typography variant="h4" component="p" sx={{ mb: 3, fontSize: { xs: "20px", sm: "20px", md: "28px", color: "#42fdff", textAlign: "center" } }}>
                                    {/* {currentNFT?.data?.name} */}
                                    {currentNFT?.data.name}
                                </Typography>
                                <Typography variant="h6" component="p" sx={{ mb: 3 }}>
                                    {/* {currentNFT?.level} */}
                                    <Grid container spacing={0}>
                                        <Grid size={{ xs: 12, sm: 6, md: 4 }} sx={{ color: "#939393" }}>Status: </Grid>
                                        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                                            {uTime == 0 ? "Active" : `Upgrading (${GetTimeString(uTime)})`}
                                        </Grid>
                                    </Grid>
                                </Typography>

                                <Typography variant="h6" component="p" sx={{ mb: 3 }}>
                                    {/* {currentNFT?.level} */}
                                    <Grid container spacing={0}>
                                        <Grid size={{ xs: 12, sm: 6, md: 4 }} sx={{ color: "#939393" }}>Level: </Grid>
                                        <Grid size={{ xs: 12, sm: 6, md: 4 }}>{currentNFT?.level}</Grid>
                                    </Grid>
                                </Typography>
                                <Typography variant="h6" component="p" sx={{ mb: 3 }}>
                                    {/* {currentNFT?.schema.schema_name} */}
                                    <Grid container spacing={0}>
                                        <Grid size={{ xs: 12, sm: 6, md: 4 }} sx={{ color: "#939393" }}>Type: </Grid>
                                        <Grid size={{ xs: 12, sm: 6, md: 4 }}>{currentNFT?.schema?.schema_name}</Grid>
                                    </Grid>
                                </Typography>
                            </Box>
                            {currentNFT?.schema.schema_name == "fawn" && <Box sx={{ width: "100%", backgroundColor: "#1c1d3e", borderRadius: "12px", textAlign: "left", color: "white", padding: "20px", boxSizing: "border-box" }}>
                                {uTime == 0 ? <Typography variant="h6" component="p" fontSize="16px">
                                    Levelup Cost: {currentNFT?.level * currentNFT?.level / 2} GRASS + {currentNFT?.level * currentNFT?.level / 2} GOLD
                                </Typography>
                                    : <Typography variant="h6" component="p" fontSize="16px">
                                        Boost Cost: {Math.ceil(uTime / 60)} EXP
                                    </Typography>}
                                <Box sx={{ textAlign: "center", mt: 1 }}>
                                    {uTime == 0
                                        ? <Button variant="contained" color="success" sx={{ maxWidth: "100%", width: "200px", padding: "12px", borderRadius: "50px", backgroundColor: "#ea923e", fontSize: { xs: "14px", sm: "14px", md: "20px" } }} onClick={() => { levelup() }}>Level Up</Button>
                                        : <Button variant="contained" color="primary" sx={{ maxWidth: "100%", width: "240px", padding: "12px", borderRadius: "50px", fontSize: { xs: "14px", sm: "14px", md: "20px" } }} onClick={() => { levelupboost() }}>Boost Level Up</Button>
                                    }
                                </Box>
                            </Box>}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    </>)
}